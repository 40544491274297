@import "../../utils/constants/Variables.scss";
.not__found__container {
  min-height: 85vh;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 20px;
  img {
    width: 500px;
    height: 500px;
  }
  span {
    font-size: 0.85em;
    color: $grey;
  }
  h1 {
    color: $primaryColor;
    margin: 5px 0;
  }
  p {
    color: $grey;
    width: 80%;
    line-height: 25px;
  }
  button {
    background: $primaryColor;
    color: $whiteColor;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    margin-top: 5px;
    cursor: pointer;
  }
}
@media screen and (max-width: 600px) {
  .not__found__container {
    flex-direction: column-reverse;
    margin: 0 20px;
    img {
      width: 300px;
      height: 300px;
    }
  }
}
