@import "../../utils/constants/Variables.scss";
.navbar {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: $primaryColor;
  height: 65px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  z-index: 10;
  justify-content: space-between;
  a {
    color: #c6d2d7;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: $whiteColor;
    }
  }
}
.navbar__left {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar__login {
  text-align: right;
  a {
    margin-right: 20px;
  }
}
.navbar_active,
.sidebar_active {
  position: relative;
  color: $whiteColor !important;
  border-bottom: 3px solid rgb(0, 255, 184);
  transition: all cubic-bezier(0.39, 0.575, 0.565, 1);
}
.navbar__logo {
  margin-left: 25px;
  // img {
  //   width: auto;
  //   height: 55px;
  // }
}

.footer__main {
  height: 40px;
  @include centerAlign;
  background-color: $primaryColor;
  width: 100%;
  color: #c6d2d7;
}
.red__alert {
  position: absolute;
  left: 4px;
  top: 4px;
}

.red__alert::before,
.red__alert::after {
  content: "";
  position: absolute;
  height: 12px;
  width: 12px;
  left: 50%;
  top: 50%;
  background-color: red;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.red__alert::before {
  animation: red__alertAnimationBefore 1s linear infinite;
}

.red__alert::after {
  animation: red__alertAnimationAfter 1s linear infinite;
}

@keyframes red__alertAnimationBefore {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }

  50% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes red__alertAnimationAfter {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
  }

  50% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
  }
}

.navbar__items {
  display: flex;
  font-size: 16px;
  position: relative;
  font-weight: 700;
  a {
    margin-left: 20px;
    position: relative;
  }
}
.navbar__login__screen {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: $primaryColor;
  height: 65px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  justify-content: space-between;
  z-index: 10;
  a {
    color: $grey;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: $whiteColor;
    }
  }
}
.navbar__single {
  a {
    margin-right: 20px;
  }
}
.sidebar__conatiner {
  width: 180px;
  border-radius: 10px;
  display: flex;
  background-color: $whiteColor;
  padding: 15px;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  margin-left: 85%;
  margin-top: 380px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  button,
  a {
    border: none;
    font-size: 15px;
    color: $primaryColor;
    cursor: pointer;
    background-color: $whiteColor;
    margin: 7px 0;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    &:hover {
      text-decoration: underline;
      transition: cubic-bezier(0.075, 0.82, 0.165, 1);
      color: $primaryColor;
    }
  }
}
.agent__sidebar {
  margin-top: 100px !important;
}
.sideBar__main {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  p {
    color: $whiteColor;
    margin-right: 25px;
    font-size: 16px;
    font-weight: 700;
  }
  img {
    margin-right: 30px;
  }
}

.learnMore {
  padding: 10px 12px !important;
  border-radius: 5px !important;
  cursor: pointer;
  // border: none;
  position: relative;
  top: 5px;
  width: 150px;
  background: none !important;
  border: 1px solid $whiteColor !important;
  color: $whiteColor !important;
}
.btn_accept {
  position: absolute;
  top: 40px;
  left: 65%;
  right: 25%;
  bottom: 40px !important;
  height: 40px;
  margin: 0 !important;
  border-radius: 5px !important;
  padding: 10px 12px !important;
}
.cookies_Popup {
  position: relative;
  z-index: 9 !important;
}
.deny__button {
  background: $whiteColor !important;
  border: 1px solid $whiteColor !important;
  color: #000 !important;
}
.btm__btns {
  display: flex;
  gap: 10px;
}
.cookie__popup__body {
  padding: 20px 20px 0 20px;
  color: $primaryColor;
  button {
    color: $whiteColor !important;
    background: $primaryColor;
    border: none;
    padding: 8px 20px;
    border-radius: 3px;
    cursor: pointer;
    margin-top: 10px;
  }
  span {
    color: $secondaryColor;
    cursor: pointer;
  }
}
.nav__more__left {
  margin-left: 70px;
}
@media only screen and (max-width: 600px) {
  .navbar__items {
    display: none;
  }

  .main__menu {
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow-x: hidden;
    width: 90%;
  }
  .mobile__main__menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 60px;
    background: $primaryColor;
    gap: 12px;
    width: 91%;
    padding: 15px;
    transition: all 0.4s ease-in;
  }
  .mobile__hamburger {
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    color: $whiteColor;
    font-size: 1.6em;
  }
  .mobile__name__side {
    display: flex;
    align-items: center;
    color: $whiteColor;
  }
  .sidebar__conatiner {
    margin-left: 5%;
    margin-top: 122vh;
    padding: 2px 10px;
    a,
    button {
      font-size: 0.8em;
    }
  }
  .sideBar__main {
    p {
      margin-right: 10px;
    }
  }
  .btn_accept {
    width: 150px;
    position: relative;
    z-index: 9999 !important;
    top: 0px;
  }
  .learnMore {
    width: 150px;
  }
  .footer__main {
    text-align: center;
    p {
      font-size: 10px;
    }
  }
}
