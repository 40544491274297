@import "../../utils/constants/Variables.scss";
.ReactModal__Overlay--after-open {
  z-index: 9;
}
.html__title__wrapper {
  width: 100%;
  height: 50px;
  background-color: #66c0ff;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
}
.html__title {
  position: relative;
  padding: 20px;
  color: #ffffff;
  font-weight: 700;
}
.otp__body {
  padding: 10px;
}
.common__btn {
  width: 210px;
  height: 50px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: none;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 15px;
  margin: 10px;
}
.submit {
  background-color: $primaryColor;
  color: $whiteColor;
  &:disabled {
    background-color: lighten($primaryColor, 20%);
  }
}
.otp__text {
  font-weight: bold;
  padding: 10px;
}
