@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap");

* {
  padding: 0;
  margin: 0;
  font-family: "Oxygen", sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}
p {
  font-size: 14px;
}
#map * {
  overflow: visible !important;
}
.ReactQueryDevtools {
  width: auto !important;
}
.error {
  color: red;
  font-size: 12px;
  margin: -10px 0 0 15px;
}
.is-invalid {
  border: 0.5px solid red !important;
  transition: all 0.3s ease;
}
::-webkit-scrollbar {
  width: 3px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: rgb(252, 252, 252);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #224373;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #6398bb;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:active {
  background: #6398bb;
  border-radius: 10px;
}
